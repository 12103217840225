export default {
  red: {
    500: '#F44336',
    300: 'E57373',
    brake: 'FF3333',
    error: '#C81922'
  },
  blue: {
    500: '#2196f3',
    transp: 'rgba(33,150,243,.13)',
    700: '#1976D2',
    trimbleblue: '#00437b',
    trimblemidblue: '#005F9E',
    trimblelightblue: '#009AD9',
    testred: '#009AD9'
  },
  green: {
    500: '#4CAF50',
    clutch: '1DE9B6'
  },
  orange: {
    400: 'FFA726',
    cruise: 'FFB531',
    danger: 'FF5252'
  },
  yellow: {
    600: '#FDD835',
    warning: 'FFEA00',
    trimbleyellow: '#FFA500'
  },
  grey: {
    200: '#EEEEEE',
    800: '#424242',
    dashboard: '#333333',
    gauge: '#666666',
    prorgessbar: '6a6a6a',
    darkgrey: 'rgba(0, 0, 0, 0.54)',
    tooltipbg: '#212121'
  },
  white: {
    default: 'rgb(255,255,255)',
    transp: 'rgba(255,255,255,0.56)'
  }
};

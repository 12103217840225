import React, {Component} from 'react';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import {
  Card,
  CardMedia,
  CardActions,
  CardContent,
  Button,
  TextField,
  FormLabel,
  Grid
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import {linkAccount as linkAccounts, clear} from '../LinkAccountActions';
import {logout} from '@peoplenet/vi-components-ui/src/app/user/UserActions';
import pnetLogo from '../../images/peoplenetbrand.png';
import Spinner from '@peoplenet/vi-components-ui/src/components/ProgressSpinner';
import uri from 'urijs';

export class LinkAccount extends Component {
  state = {
    companyId: '',
    password: ''
  }

  componentDidMount() {
    document.getElementById('backgroundColor').className = 'logingradient linkaccounts';
  }

  componentWillUnmount() {
    document.getElementById('backgroundColor').className = '';
    this.props.clear();
  }

  onChange = e => {
    const {target} = e;
    const {name} = target;
    let {value} = target;

    if (name === 'companyId') {
      const lastValue = this.state[name];
      if (/^[0-9]*$/.test(value) === false) {
        value = lastValue;
      }
    }

    this.setState({
      [name]: value
    });
  }

  isValid = () => {
    return this.state.companyId && this.state.password;
  }

  submit = () => {
    if (this.isValid()) {
      ReactGA.event({
        category: 'Login',
        action: 'submit',
        label: 'Link Account'
      });
      const {companyId, password} = this.state;
      this.props.linkAccounts(companyId, password, this.props.onLink);
    }
  }

  showError= () => {
    const {errorMessage, showError} = this.props;
    if (errorMessage && showError) {
      const errors = {
        migrated: 'The user has already migrated their PFM account',
        password: 'The entered CID or password is incorrect',
        linked: 'The user has already been linked',
        error: 'Error linking account'
      };

      return <div className="linkaccounts__errorlabel">{errors[errorMessage]}</div>;
    }
    return <div />;
  }

  handleLogout = () => {
    this.props.logout();
  }
  redirectTTL = () => {
    localStorage.setItem('systemId', 'ttl');
    window.location.href = `${uri(TRIMBLE_ID_VI_MOBILE_HOST)}?ref=ttl`;
  }

  renderContent() {
    if (this.props.loading) {
      return (
        <CardContent className="linkaccounts__content">
          <Typography type="headline" component="h2">
            Link Your Account
          </Typography>
          <Typography component="div">
            Link account is processing and will be done shortly.
            <Spinner show="true" />
          </Typography>
        </CardContent>
      );
    }

    return (
      <CardContent className="linkaccounts__content">
        <Typography type="headline" component="h2">
          Link Your Account
        </Typography>
        <Typography component="p">
          One last step! We need to link your PFM Account to ensure your
          PFM permissions and settings carryover.
        </Typography>
        <Typography component="p">
          Please enter the <strong>Company ID</strong> and <strong>Password&nbsp;</strong>
          you use to login to the PeopleNet Fleet Manager and click Link Account.
        </Typography>
        <FormLabel>
          <TextField
            label="Company ID"
            name="companyId"
            id="companyId"
            onChange={this.onChange}
            value={this.state.companyId}
            InputProps={{disableUnderline: true}}
            className="linkaccounts__textfield"
          />
        </FormLabel>
        <FormLabel>
          <TextField
            label="PFM Password"
            type="password"
            name="password"
            id="password"
            onChange={this.onChange}
            InputProps={{disableUnderline: true}}
            value={this.state.password}
            className="linkaccounts__textfield"
          />
        </FormLabel>
        {this.showError()}
        <Typography component="label" className="linkaccounts__support">
          <Icon className="linkaccounts__headset">headset_mic</Icon>
          <span>
            If you do not know your Company ID, please contact support at&nbsp;
            <strong>888-346-3486</strong>.
          </span>
        </Typography>
      </CardContent>
    );
  }

  renderActions() {
    if (!this.props.loading) {
      return (
        <Grid container direction="row" justify="center" alignItems="center">
          <CardActions className="linkaccounts__actions">
              <Button variant="contained" onClick={this.handleLogout} id="cancel">Cancel</Button>
              <Button
                id="confirm"
                variant="contained"
                color="primary"
                disabled={!this.isValid()}
                onClick={this.submit}
                className="primary"
              >Link Account</Button>
          </CardActions>
          <Grid container className="linkaccounts__actions">
            <Button variant="contained" onClick={this.redirectTTL} id="eu_redirect_btn">I'M A EUROPEAN USER</Button>
          </Grid>
        </Grid>
      );
    }

    return null;
  }

  render() {
    return (
      <Card className="linkaccounts__card">
        <CardMedia image={pnetLogo} className="linkaccounts__media" />
        {this.renderContent()}
        {this.renderActions()}
      </Card>
    );
  }
}

export const mapStateToProps = state => {
  const {linkAccount} = state;
  return {...linkAccount, showError: linkAccount.error};
};

export default connect(mapStateToProps, {
  linkAccounts,
  clear,
  logout
})(LinkAccount);

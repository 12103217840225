import {
  LOAD_SYSTEM_SETTINGS_REQUEST,
  LOAD_SYSTEM_SETTINGS_SUCCESS,
  LOAD_SYSTEM_SETTINGS_FAIL
} from './SystemActionsType';

const initialState = {
  loading: false,
  settings: null
};

export default (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case LOAD_SYSTEM_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_SYSTEM_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: payload
      };
    case LOAD_SYSTEM_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        settings: {}
      };
    default:
      return state;
  }
};

import React from 'react';
import {connect} from 'react-redux';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import ProgressSpinner from '@peoplenet/vi-components-ui/src/components/ProgressSpinner';
import {logout} from '@peoplenet/vi-components-ui/src/app/user/UserActions';

const theme = createMuiTheme();

class Spinner extends React.Component {
  componentDidMount = () => {
    if (!this.props.auth.isAuthenticated()) {
      this.props.logout();
    }
  }

  render() {
    return (
      // TODO: REMOVE MuiThemeProvider after next deploy to prod
      <MuiThemeProvider theme={theme}>
        <div>
          <div className="whitecover" />
          <div className="bodycontent">
            <ProgressSpinner show />
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, {
  logout
})(Spinner);

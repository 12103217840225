import systemService from './SystemService';
import {
  LOAD_SYSTEM_SETTINGS_REQUEST,
  LOAD_SYSTEM_SETTINGS_SUCCESS,
  LOAD_SYSTEM_SETTINGS_FAIL
} from './SystemActionsType';

export const getSystemSettings = () => async dispatch => {
  dispatch({
    type: LOAD_SYSTEM_SETTINGS_REQUEST
  });

  try {
    const settings = await systemService.getSettings();

    dispatch({
      type: LOAD_SYSTEM_SETTINGS_SUCCESS,
      payload: settings
    });

    return settings;
  } catch (e) {
    console.error(e);
    dispatch({
      type: LOAD_SYSTEM_SETTINGS_FAIL
    });
  }
  return null;
};

import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';
import {composeWithDevTools} from 'redux-devtools-extension';
import {routerMiddleware} from 'connected-react-router';
import reducers from '../reducers';
import api from '@peoplenet/vi-components-ui/src/middleware/api';
import history from '@peoplenet/vi-components-ui/src/app/history';
import storeProvider from '@peoplenet/vi-components-ui/src/store';

function reduxStore(initialState, historyMiddleware) {
  let enhancer;
  if (TARGET_ENV === 'development') {
    enhancer = composeWithDevTools(
      applyMiddleware(thunk, api, createLogger(), historyMiddleware)
    );
  } else {
    enhancer = compose(
      applyMiddleware(thunk, api, historyMiddleware)
    );
  }

  const store = createStore(reducers(history), initialState, enhancer);
  storeProvider.setStore(store);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      // We need to require for hot reloading to work properly.
      const nextReducer = require('../reducers'); // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

export default reduxStore({}, routerMiddleware(history));

import React from 'react';
import {connect} from 'react-redux';
import {MuiThemeProvider} from '@material-ui/core/styles';
import MobileTheme from './MobileTheme';
import DesktopTheme from './DesktopTheme';

const ThemeProvider = props => {
  const theme = props.isMobile ? MobileTheme : DesktopTheme;
  return (
    <MuiThemeProvider theme={theme}>
      {props.children}
    </MuiThemeProvider>
  )
}

const mapStateToProps = state => {
  return {...state.configuration};
}

export default connect(mapStateToProps)(ThemeProvider);

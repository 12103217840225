/* CAUTION: When using the generators, this file is modified in some places.
 *          This is done via AST traversal - Some of your formatting may be lost
 *          in the process - no functionality should be broken though.
 *          This modifications only run once when the generator is invoked - if
 *          you edit them, they are not updated again.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Login from './Login';
import TrimbleIdLogin from '../containers/TrimbleIdLogin';
import '../styles/index.scss';
import '../static/flowplayer/skin/skin.css';

/* Populated by react-webpack-redux:reducer */
const App = props => {
  const {actions, auth} = props;
  if (TRIMBLE_ID_FEATURE_TOGGLE) {
    return (
      <TrimbleIdLogin actions={actions} auth={auth} hash={props.location.hash} />
    );
  }
  return (
    <Login actions={actions} auth={auth} hash={props.location.hash} />
  );
};

/* Populated by react-webpack-redux:reducer
 *
 * HINT: if you adjust the initial type of your reducer, you will also have to
 *       adjust it here.
 */
App.propTypes = {
  actions: PropTypes.shape({}).isRequired
};
function mapStateToProps(state) { // eslint-disable-line no-unused-vars
  /* Populated by react-webpack-redux:reducer */
  const props = {};
  return props;
}
function mapDispatchToProps(dispatch) {
  /* Populated by react-webpack-redux:action */
  const actions = {};
  const actionMap = {actions: bindActionCreators(actions, dispatch)};
  return actionMap;
}
export default connect(mapStateToProps, mapDispatchToProps)(App);

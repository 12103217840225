import {connectRouter} from 'connected-react-router';
import {combineReducers} from 'redux';
import {i18nReducer} from 'react-redux-i18n';
import configuration from '@peoplenet/vi-components-ui/src/app/configuration/configurationReducer';
import switchCustomer from '@peoplenet/vi-components-ui/src/SwitchCustomer/SwitchCustomerReducer';
import library from '@peoplenet/vi-components-ui/src/Library/LibraryReducer';
import dashboard from '@peoplenet/vi-components-ui/src/Dashboard/DashboardReducer';
import libraryFilters from '@peoplenet/vi-components-ui/src/LibraryFilter/libraryFilterReducer';
import filterReducer from '@peoplenet/vi-components-ui/src/components/DesktopFilter/filterReducer';
import player from '@peoplenet/vi-components-ui/src/reducers/player';
import videoTagging from '@peoplenet/vi-components-ui/src/reducers/videoTagging';
import user from '@peoplenet/vi-components-ui/src/app/user/UserReducer';
import onDemand from '@peoplenet/vi-components-ui/src/OnDemand/OnDemandReducer';
import report from '@peoplenet/vi-components-ui/src/report/ReportReducer';
import reportFilter from '@peoplenet/vi-components-ui/src/report/filters/ReportFiltersReducer';
import downloadVideo from '@peoplenet/vi-components-ui/src/DownloadVideo/DownloadVideoReducer';
import dvr from '@peoplenet/vi-components-ui/src/DVRhealth/DVRhealthReducer';
import dvrFilter from '@peoplenet/vi-components-ui/src/DVRhealth/filters/HealthFiltersReducer';
import dvrSnapshots from '@peoplenet/vi-components-ui/src/DVRSnapshots/DVRSnapshotsReducer';
import userSettings from '@peoplenet/vi-components-ui/src/UserSettings/UserSettingsReducer';
import notification from '@peoplenet/vi-components-ui/src/Notification/NotificationReducer';
import system from '../system/SystemReducer';
import customer from './customer';
import linkAccount from '../linkAccount/LinkAccountReducer';

export default history => combineReducers({
  router: connectRouter(history),
  configuration,
  filterReducer,
  library,
  libraryFilters,
  linkAccount,
  player,
  i18n: i18nReducer,
  system,
  customer,
  switchCustomer,
  videoTagging,
  user,
  onDemand,
  report,
  reportFilter,
  downloadVideo,
  dvr,
  dvrFilter,
  dvrSnapshots,
  dashboard,
  userSettings,
  notification
});

import {push} from 'connected-react-router';
import store from '../../stores';
import {VEHICLE_ONDEMAND_KEY} from '@peoplenet/vi-components-ui/src/app/configuration/localstorageKeys';

class Auth {
  setSession(authResult) {
    if (authResult && authResult.accessToken && authResult.idToken) {
      // Set the time that the access token will expire at
      const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());

      localStorage.setItem('access_token', authResult.accessToken);
      localStorage.setItem('id_token', authResult.idToken);
      localStorage.setItem('expires_at', expiresAt);

      if (this.onLogin) {
        this.onLogin(authResult);
      }
    }
  }

  logout() {
    // TODO: remove
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('changeCustomer');
    localStorage.removeItem(VEHICLE_ONDEMAND_KEY);
    store.dispatch(push('/'));
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }
}

export default new Auth();

import React from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {loadUserInformation} from '@peoplenet/vi-components-ui/src/app/user/UserActions';
import {popQueryParameter, REDIRECT_KEY} from '@peoplenet/vi-components-ui/src/app/historyUtil';

class LoginTrimbleId extends React.PureComponent {
  async componentDidMount() {
    const {
      dispatch,
      auth,
      onLogin
    } = this.props;

    if (!auth.isAuthenticated()) {
      auth.handleAuthentication(onLogin);
    }

    if (auth.isAuthenticated() && !this.props.loading) {
      // TODO: check whether or not this is necessary
      dispatch(loadUserInformation());
      dispatch(push(this.redirectPath));
    }
  }

  get redirectPath() {
    const redirectPath = popQueryParameter(REDIRECT_KEY);
    if (redirectPath) {
      return redirectPath;
    }
    return '/library';
  }

  removeAnchorFromUrl = url => {
    return url.replace('#', '?');
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({loading: state.customer.loading});

export default connect(mapStateToProps)(LoginTrimbleId);

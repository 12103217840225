import React from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {loadUserInformation} from '@peoplenet/vi-components-ui/src/app/user/UserActions';
import {popQueryParameter, REDIRECT_KEY} from '@peoplenet/vi-components-ui/src/app/historyUtil';

class Login extends React.Component {
  componentWillMount() {
    this.props.auth.hide();
    document.body.className = 'logingradient';
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated()) {
      // TODO: check whether or not this is necessary
      this.props.loadUserInformation();
      // Redirect user to previously visited path (set uppon login redirect)
      let redirectPath = popQueryParameter(REDIRECT_KEY);
      if (redirectPath) {
        window.location.pathname = redirectPath;
      } else {
        redirectPath = '/library';
      }
      this.props.push(redirectPath);
    } else if (this.props.hash) {
      this.props.auth.onLogin = () => this.props.loadUserInformation();
      this.props.auth.handleAuthentication();
    } else {
      this.props.auth.login(this.props.onLogin);
    }
  }

  componentWillUnmount() {
    document.body.className = '';
  }

  render() {
    return (
      <div className="table">
        <div className="cell">
          <div id="login-widget" />
        </div>
      </div>
    );
  }
}

Login.defaultProps = {};

export default connect(null, {push, loadUserInformation})(Login);

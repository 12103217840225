import React from 'react';
import ReactDOM from 'react-dom';
import {AppContainer} from 'react-hot-loader';
import {Provider} from 'react-redux';
import store from './stores';
import Wrapper from './app/BetaWrapper';
import Theme from './app/theme';
import translation from '@peoplenet/vi-components-ui/src/app/translation';

const render = Component => {
  translation();
  ReactDOM.render(
    <AppContainer warnings={false}>
      <Provider store={store}>
        <Theme>
          <Component />
        </Theme>
      </Provider>
    </AppContainer>,
    document.getElementById('app')
  );
};

const renderApp = () => {
  render(Wrapper);
};

renderApp();

if (module.hot) {
  module.hot.accept(renderApp);
}

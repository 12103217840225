import colors from './colors';

export default {
  overrides: {
    MuiBadge: {
      badge: {
        right: '10px',
        backgroundColor: colors.yellow[`trimbleyellow`]
      }
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '890px',
        maxHeight: '100vh'
      }
    },
    MuiBottomNavigation: {
      root: {
        boxShadow: '0 1px 3px 1px rgba(0,0,0,.2), 0 1px 1px 1px rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12)',
        zIndex: 2000
      }
    },
    MuiBottomNavigationAction: {
      label: {
        fontFamily: 'Open Sans, sans-serif'
      }
    },
    PlayerBottomNav: {
      root: {
        boxShadow: '0 1px 3px 1px rgba(0,0,0,.2), 0 1px 1px 1px rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12)',
        zIndex: 2000
      }
    },
    MuiDrawer: {
      paper: {
        width: '84.4444%',
        maxWidth: '320px'
      }
    },
    MuiTooltip: {
      tooltip: {
        maxWidth: '200px',
        backgroundColor: colors.grey[`tooltipbg`]
      }
    }
  },
  palette: {
    primary: {
      light: colors.blue['trimblelightblue'],
      main: colors.blue['trimblemidblue'],
      dark: colors.blue[`trimbleblue`]
    },
    secondary: {
      light: colors.blue['trimblelightblue'],
      main: colors.blue['trimblemidblue'],
      dark: colors.blue[`trimbleblue`]
    },
    error: {
      main: colors.red.error
    }
  }
};

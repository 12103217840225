import {startsWith, get} from 'lodash';
import linkAccountService from './service/LinkAccountService';
import {
  LINK_ACCOUNT_REQUEST,
  LINK_ACCOUNT_SUCCESS,
  LINK_ACCOUNT_CLEAR,
  LINK_ACCOUNT_ERROR
} from './LinkAccountActionsType';

export const linkAccount = (customerId, password, callback) => {
  return async dispatcher => {
    try {
      dispatcher({type: LINK_ACCOUNT_REQUEST});
      await linkAccountService.post({customerId, password});
      dispatcher({type: LINK_ACCOUNT_SUCCESS});
      if (typeof callback === 'function') {
        callback();
      }
    } catch (err) {
      let errorMessage;
      console.log(err);
      if (err.status === 422 && startsWith(get(err, 'body.message', '').toLowerCase(), 'invalid pfm company')) {
        console.error('invalid password');
        errorMessage = 'password';
      } else if (err.status === 422 && startsWith(get(err, 'body.message', '').toLowerCase(), 'pfm company id/password is for a different user')) {
        console.error('The user has already been linked');
        errorMessage = 'linked';
      } else if (err.status === 422 && startsWith(get(err, 'body.message', '').toLowerCase(), 'pfm user has already been migrated')) {
        console.error('Already migrated');
        errorMessage = 'migrated';
      } else {
        console.error('Error linking account.', err);
        errorMessage = 'error';
      }
      dispatcher({type: LINK_ACCOUNT_ERROR, payload: errorMessage});
    }
  };
};

export const clear = () => dispatcher => {
  dispatcher({type: LINK_ACCOUNT_CLEAR});
};

import React from 'react';
import {connect} from 'react-redux';
import {Route, Redirect} from 'react-router';
import auth from '../components/Auth/Auth';
import trimbleAuth from '@peoplenet/vi-components-ui/src/components/Auth/TrimbleId';
import ViAppBar from '@peoplenet/vi-components-ui/src/components/ViAppBar';
import {hasAccess as hasRole} from '@peoplenet/vi-components-ui/src/app/user';
import {isMobile} from '@peoplenet/vi-components-ui/src/app/configuration/util';
import DVRMobileFilters from '@peoplenet/vi-components-ui/src/DVRhealth/filters/HealthMobileFilterBar';

const ProtectedRoute = receivedProps => {
  const {
    user,
    component: Component,
    title,
    roles,
    disabledOnMobile,
    isMobile,
    path,
    ...props
  } = receivedProps;

  const hasAccess = () => {
    if (TRIMBLE_ID_FEATURE_TOGGLE && trimbleAuth.isAuthenticated()) {
      return hasRole(roles);
    } else if (auth.isAuthenticated()) {
      return hasRole(roles);
    }
    return false;
  };

  // Check if page is accessible on the current view mode
  const isAccessible = () => {
    if (disabledOnMobile && isMobile) {
      return false;
    }

    return true;
  };

  if (hasAccess() && isAccessible()) {
    return (
      <Route
        {...props}
        render={p => {
          return (
            <React.Fragment>
              <ViAppBar title={title}>
                {isMobile && path === '/dvr-health' && <DVRMobileFilters />}
              </ViAppBar>
              <div className="bodycontent bodycontent__playerdesktop">
                <Component {...p} />
              </div>
            </React.Fragment>
          );
        }}
      />
    );
  }

  return <Redirect to="/" />;
};

export default connect(state => ({isMobile: isMobile(state)}))(ProtectedRoute);

import {createMuiTheme} from '@material-ui/core/styles';
import theme from './theme';

// Styles that should be applied for mobile only, should be placed here

const appTheme = {
  ...theme,
  overrides: {
    ...theme.overrides
  }
};


export default createMuiTheme(appTheme);

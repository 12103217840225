import BaseService from '../middleware/BaseService';

class SystemService extends BaseService {
  
  prefix = '/system/settings'

  getSettings() {
    return this.get();
  }

}

export default new SystemService();

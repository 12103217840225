import {get} from 'lodash';
import {
  LINK_ACCOUNT_REQUEST,
  LINK_ACCOUNT_SUCCESS,
  LINK_ACCOUNT_CLEAR,
  LINK_ACCOUNT_ERROR
} from './LinkAccountActionsType';

export const initialState = {
  error: false,
  errorMessage: '',
  loading: false
};

const reducer = (state = initialState, action) => {
  const type = get(action, 'type');
  switch (type) {
    case LINK_ACCOUNT_CLEAR:
      return {...state, ...initialState};
    case LINK_ACCOUNT_REQUEST:
      return {...state, loading: true};
    case LINK_ACCOUNT_SUCCESS:
      return {...state, loading: false};
    case LINK_ACCOUNT_ERROR:
      return {...state, loading: false, error: true, errorMessage: action.payload};
    default:
      return state;
  }
};

export default reducer;

import {get, isString, forEach} from 'lodash';
import store from '../stores';
import { logout } from '@peoplenet/vi-components-ui/src/app/user/UserActions';

class BaseApi {
  async fetch(path, options = {}) {
    const requestOp = {
      ...options
    };
    requestOp.headers = options.headers || {};
    requestOp.headers.Authorization = this.token;
    requestOp.headers.togsToken = this.togsToken;
    requestOp.headers.pfm2SessionId_customerId = this.pfmToken;
    requestOp.headers.customerId = this.customerId;

    if (TRIMBLE_ID_FEATURE_TOGGLE) {
      requestOp.headers.accessToken = this.accessToken;
    }

    if (!isString(requestOp.headers['Content-Type'])) requestOp.headers['Content-Type'] = 'application/json';

    if (options.body && !isString(options.body)) requestOp.body = JSON.stringify(options.body);

    const url = this.buildUrl(path);
    const res = options.local ? await fetch(url, requestOp) : await fetch(`${this.url}/${url.replace(/^\//, '')}`, requestOp);
    if (res.ok) {
      if (requestOp.headers['Content-Type'].indexOf('octet-stream') > 0) {
        return this.getBlob(res);
      }
      return this.tryJson(res);
    }

    const body = await this.tryJson(res);
    const {status, statusText} = res;
    const result = {status, statusText, body};
    console.error('Http Error:', result);
    if (result.status === 401) {
      store.dispatch(logout());
    }
    return Promise.reject(result);
  }

  async tryJson(res) {
    const text = await res.text();

    if (!text || text.trim() === '') return text;
    try {
      return this.parseJson(text);
    } catch (ex) {
      console.error(ex);
      return text;
    }
  }

  buildUrl(url) {
    const systemId = localStorage.getItem('systemId');

    let prefix = '';

    if (systemId === 'peoplenet') {
      prefix = 'api';
    } else if (systemId === 'fsm') {
      prefix = 'fsm';
    } else if (systemId === 'ttl') {
      prefix = 'ttl';
    } else if (systemId === 'togs') {
      prefix = 'togs';
    } else {
      prefix = 't';
    }

    return prefix + url;
  }

  parseJson(responseText) {
    const responseJson = JSON.parse(responseText);

    const hasCustomerId = ['cid', 'customerId'].some(key => responseText.includes(key));

    if (!hasCustomerId) {
      return responseJson;
    }

    this.checkCustomerIdProperty(responseJson);

    return responseJson;
  }

  checkCustomerIdProperty(object) {
    if (!object) {
      return;
    }

    if (typeof(object) !== 'object') {
      return;
    }

    if (object instanceof Array) {
      object.forEach(item => {
        this.checkCustomerIdProperty(item);
      });

      return;
    }

    if (object['cid'] && !object['customerId']) {
      object['customerId'] = object['cid'];
      return;
    } else if (object['customerId'] && !object['cid']) {
      object['cid'] = object['customerId'];
      return;
    }

    forEach(object, value => {
      this.checkCustomerIdProperty(value);
    });
  }

  async getBlob(res) {
    return await res.blob();
  }

  get(url, options) {
    return this.fetch(url, options);
  }

  post(url, body) {
    return this.fetch(url, {method: 'POST', body});
  }

  put(url, body) {
    return this.fetch(url, {method: 'PUT', body});
  }

  patch(url, body) {
    return this.fetch(url, {method: 'PATCH', body});
  }

  remove(url, body) {
    return this.fetch(url, {method: 'DELETE', body});
  }

  get url() {
    return API_URL;
  }

  get token() {
    return localStorage.getItem('id_token');
  }

  get accessToken() {
    return localStorage.getItem('access_token');
  }

  get customerId() {
    const customerId = get(store.getState(), 'user.userInformation.customerId', null);
    return customerId;
  }

  get togsToken() {
    return localStorage.getItem('togstoken');
  }

  get pfmToken() {
    return localStorage.getItem('pfm2SessionId_customerId');
  }
}

export default new BaseApi();
